<template>
  <v-main>
    <v-container fluid class="h-100">
      <v-row justify="center" align="center" class="h-50">
        <h2>Error 404</h2>
      </v-row>
      <v-row justify="center" align="center">
        <v-btn @click="goBack">
          Volver
        </v-btn>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
  .h-100 {
    height: 100%;
  }
  .h-50 {
    height: 50%;
  }
</style>
